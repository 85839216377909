<template>
  <div class="stamp-duty-confirm">
    <fd-form class="card" @submit="submitForm">
      <modal-body class="p-3">
        <h4 class="mb-1">Confirm {{ type }} Stamp Duty Paid</h4>
        <p class="mb-4">Select the date when the stamp duty is paid</p>

        <fd-date-picker
          v-model="stampDutyDate"
          class="col-12 px-1 mb-2"
          :label="`${type} Stamp Duty Date`"
          name="stampDutyDate"
          :validators="[validator.required]"
        >
        </fd-date-picker>
      </modal-body>
      <modal-footer class="row justify-content-end p-2">
        <fd-button @click="$emit('cancel')">Cancel</fd-button>
        <fd-button type="submit" class="main ml-1">Confirm</fd-button>
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    type: {
      type: String,
      required: true
      // SPA, LA
    }
  },
  data: function () {
    return {
      stampDutyDate: "",

      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    submitForm() {
      let payload;
      if (this.type == "LA") {
        payload = { loanAgreementStampDutyIsPaidDate: this.stampDutyDate };
      } else if (this.type == "SPA") {
        payload = {
          salesPurchaseAgreementStampDutyIsPaidDate: this.stampDutyDate
        };
      }

      this.$emit("submit", this.type, payload);
    }
  }
};
</script>

<style lang="scss">
.stamp-duty-confirm {
  min-width: 400px;
}
</style>
